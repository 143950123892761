//
// Icon buttons
//

// General styles
.navbar-brand-img {
   max-height: 100px !important;
}
.avatar-sm {
   align-items: initial !important;
}
.actionbtn {
   position: absolute;
   top: 10px;
   right: 10px;
}
.footer {
   background: transparent !important;
}
.btn-rounded {
   border-radius: 50%;
}
.imgwraped {
   height: 250px;
   overflow: hidden;
   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
}
.color_flow {
   display: inline-block;
   .color {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
   }
}
.variant_wrap {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   .variant_list {
      margin-bottom: 1px;
      width: 25%;
      padding: 5px 20px;
      background: #eee;
      &#variantid {
         display: none;
      }
      .variant_item_title {
         font-weight: 600;
         font-size: 15px;
         text-transform: uppercase;
         margin-bottom: 5px;
      }
      .variant_item_value {
         font-size: 18px;
      }
   }
}
.btn {
   position: relative;
   text-transform: $btn-text-transform;
   transition: $transition-base;
   letter-spacing: $btn-letter-spacing;
   font-size: $input-btn-font-size;
   will-change: transform;

   &:hover {
      @include box-shadow($btn-hover-box-shadow);
      transform: translateY($btn-hover-translate-y);
   }

   &:not(:last-child) {
      margin-right: 0.5rem;
   }

   // Icons

   i:not(:first-child),
   svg:not(:first-child) {
      margin-left: 0.5rem;
   }

   i:not(:last-child),
   svg:not(:last-child) {
      margin-right: 0.5rem;
   }
}

// Remove translateY and margin animation when btn is included in a btn-group or input-group

.btn-group,
.input-group {
   .btn {
      margin-right: 0;
      transform: translateY(0);
   }
}

// Size variations

.btn-sm {
   font-size: $input-btn-font-size-sm;
}

.btn-lg {
   font-size: $input-btn-font-size-lg;
}

// Some quick fixes (to revise)

// Fixes
[class*="btn-outline-"] {
   border-width: 1px;
}

.btn-outline-secondary {
   color: darken(theme-color("secondary"), 50%);
}

.btn-inner--icon {
   i:not(.fa) {
      position: relative;
      top: 2px;
   }
}

.btn-link {
   font-weight: $btn-font-weight;
   box-shadow: none;

   &:hover {
      box-shadow: none;
      transform: none;
   }
}

.btn-neutral {
   color: theme-color("primary");
}
